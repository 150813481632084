import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "./css/message.css";
import "./css/notice.css";
const cardData = [
  {
    id: 1,
    heading: "this is our staff number 1",
    subheading:
      "I am delighted to have the opportunity to stand before you today as an educator with over 12 years of experience in the teaching profession. Throughout this remarkable journey, I have had the privilege of sharing knowledge, inspiring young minds, and witnessing the growth of countless students. It is both an honor and a pleasure to be a part of your academic journey.",

    image: "images/test_img/teacher.jpg",
  },
  {
    id: 2,
    heading: "Card 2 Heading",
    subheading: "Hello every one  ",
    image: "images/test_img/sl2.jpg",
  },
  // Add more card data as needed
];

const MessageSlider = () => {
  const apiUrl = process.env.REACT_APP_API_URI;

  const [message, SetMessage] = useState([]);

  const handleMessageSlider = async () => {
    try {
      await axios.get(`${apiUrl}diet/message/getMessage`).then((res) => {
        const data = res.data.data;
        SetMessage(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
  };

  useEffect(() => {
    handleMessageSlider();
  }, []);

  return (
    <div className="card-slider container ">
      <Slider {...settings}>
        {message.map((card) => (
          <div key={card.id} className="message ">
            <img
              src={`${apiUrl}${card.image}`}
              alt={`Card ${card.id}`}
              className="slider-image"
            />
            <div className="message-content ">
              <h2>{card.facultyName}</h2>
              <p>{card.message}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MessageSlider;
