import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

function Event_Details() {
    const { id } = useParams();
    const apiurl = process.env.REACT_APP_API_URI;
    const [EventData, setEventData] = useState([]);
    console.log(EventData);

    const handelEventData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/event/getOne/${id}`)
                .then((result) => {
                    setEventData(result.data);
                    onLoadContent(result.data.Desc, result.data._id);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Convert text file to text format
    const onLoadContent = async (descText, divId) => {
        // console.log(`${apiurl}${descText}`);
        await fetch(`${apiurl}${descText}`)
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                const content = document.getElementById(divId);
                content.innerHTML = data;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        handelEventData();
    }, []);

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Event Details</h1>
                            <p>Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut pellentesque maximus lacus, nec pharetra augue.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container com-sp">
                    <div className="row">
                        <div className="cor about-sp">
                            <div className="ed-about-tit">
                                <div>
                                    <div className="ho-event pg-eve-main pg-blog">
                                        <ul>
                                            <li>
                                                <div className="ho-ev-date pg-eve-date">
                                                    <span>07</span><span>jan,2018</span>
                                                    {/* <span>{format(parseISO(EventData.Event_date), 'dd')}</span><span>{format(parseISO(EventData.Event_date), 'MMM yyyy')}</span> */}
                                                </div>
                                                <div className="pg-eve-desc pg-blog-desc">
                                                    <h4>{EventData.Event_Name}</h4>
                                                    <img src={`${apiurl}/${EventData.Image}`} alt="error" style={{ height: '40rem' }} />
                                                    <p id={EventData._id}>
                                                        Description Not Uploaded
                                                    </p>
                                                    {/* <span>9:15 am – 5:00 pm</span> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Event_Details