import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Faculty_Details() {
    const { id } = useParams();
    const apiurl = process.env.REACT_APP_API_URI;
    const [facultyData, setFacultyData] = useState([]);
    console.log(facultyData);

    // Get the current page data from the server
    const handelFacultyData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/faculty/getOne/${id}`)
                .then((result) => {
                    setFacultyData(result.data.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handelFacultyData();
    }, []);

    return (
        <section>
            <div className="pro-cover">
            </div>
            <div className="pro-menu">
                <div className="container">
                    <div className="col-md-9 col-md-offset-3">
                        <ul>
                            <li><a href="db-profile.html" className="pro-act">Profile</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="stu-db">
                <div className="container pg-inn">
                    <div className="col-md-3">
                        <div className="pro-user">
                            {/* <img src="images/user.jpg" alt="user" /> */}
                            <img src={`${apiurl}/${facultyData.Image}`} alt="user" style={{ width: '253px', height: '253px' }} />
                        </div>
                        <div className="pro-user-bio">
                            <ul>
                                <li>
                                    <h4 className="text-capitalize">{facultyData.Name}</h4>
                                </li>
                                <li>Faculty Id: {facultyData.Faculty_Id}</li>
                                <li><a href="#!"><i className="fa fa-facebook" /> Facebook: my sample</a></li>
                                <li><a href="#!"><i className="fa fa-google-plus" /> Google: my sample</a></li>
                                <li><a href="#!"><i className="fa fa-twitter" /> Twitter: my sample</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="udb">
                            <div className="udb-sec udb-prof">
                                <h4><img src="images/icon/db1.png" alt="error" /> My Profile</h4>
                                <p className="text-justify">{facultyData.Description}</p>
                                <div className="sdb-tabl-com sdb-pro-table">
                                    <table className="table bordered">
                                        <tbody>
                                            <tr>
                                                <td>Faculty Name</td>
                                                <td>:</td>
                                                <td>{facultyData.Name}</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty Id</td>
                                                <td>:</td>
                                                <td>{facultyData.Faculty_Id}</td>
                                            </tr>
                                            <tr>
                                                <td>Designation</td>
                                                <td>:</td>
                                                <td><span>{facultyData.Designation}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Year of experience</td>
                                                <td>:</td>
                                                <td>{facultyData.year_of_Exp}</td>
                                            </tr>
                                            <tr>
                                                <td>Eamil</td>
                                                <td>:</td>
                                                <td>{facultyData.Email}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>:</td>
                                                <td><span className="db-done">Active</span> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Faculty_Details