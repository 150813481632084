import React from 'react';
import Modal from './Modal';
import { Styles } from "./css/footer.js";

function Footer() {
    return (
        <Styles>
            <Modal />

            {/* FOOTER COURSE BOOKING */}
            <section>
                <div className="full-bot-book">
                    <div className="container">
                        <div className="row">
                            <div className="bot-book">
                                <div className="col-md-2 bb-img">
                                    <img src="images/3.png" alt="error" />
                                </div>
                                <div className="col-md-7 bb-text">
                                    <h4>Though of the day</h4>
                                    <p>Education is not just about memorizing facts; it’s about igniting a passion for learning.</p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* FOOTER */}
            <section className="wed-hom-footer">
                <div className="container">
                    <div className="row wed-foot-link-1 d-flex justify-content-evenly">
                        <div className="col-md-4 foot-tc-mar-t-o">
                            <h4>Get In Touch</h4>
                            <p>Address: DIET Mahasamund,BTI Road, Gaurav Path, Mahasamund, Chhattisgarh</p>
                            <p>Phone: <a href="#!">07723-224781</a></p>
                            <p>Email: <a href="#!">dietmahasamund@gmail.com</a></p>
                        </div>
                      
                        <div className="col-md-6">
                          
                           
                        </div>
                    </div>
                </div>
            </section>

            {/* COPY RIGHTS */}
            <section section className="copyright-area" >
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="copy-text text-left">
                                <p>
                                    Designed With{" "}
                                    <i class="fa-solid fa-heart"></i> by{" "}
                                    <a href={"https://prixso.com/"} target="_blank" rel="noopener noreferrer">
                                        Prixso Software
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 text-right">
                          
                        </div>
                    </div>
                </div>
            </section>

            {/* SOCIAL MEDIA SHARE */}
         

        </Styles>
    )
}

export default Footer