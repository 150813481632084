import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./css/training.css";
import { format, parseISO } from 'date-fns';
import axios from 'axios';

function Training() {
    const colors = ["#0D6EFD", "#6C757D", "#198754", "#DC3545", "#FFC107", "#0DCAF0", "#D63384"];
    const apiurl = process.env.REACT_APP_API_URI;
    const [trainingData, setTrainingData] = useState([]);
    const [pageNumber, setPageNumber] = useState();
    const [pagination, setPagination] = useState([]);
    console.log(trainingData);

    // It use for show number of pages
    const handelAllData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/training/getOne`)
                .then((result) => {
                    let count = result.data.length / 8;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [...current, <li className="waves-effect middlePageNo" key={i} onClick={(e) => setPageNumber(i + 1)}><Link>{i + 1}</Link></li>]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Get the current page data from the server
    const handelTrainingData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/training/get/${pageNumber}`)
                .then((result) => {
                    setTrainingData(result.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }


    // when changing the page number then it call
    useEffect(() => {
        handelTrainingData();

        // Hide and show previous button
        const prePage = document.getElementsByClassName("prePage");
        pageNumber > 1 ? prePage[0].classList.remove("hidden") : prePage[0].classList.add("hidden");

        // Hide and show next button
        const nextPage = document.getElementsByClassName("nextPage");
        if (pagination.length) {
            pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
        }
    }, [pageNumber]);

    useEffect(() => {
        setPageNumber(1)
        handelAllData();
    }, [])

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Trainings</h1>
                            <p>Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut pellentesque maximus lacus, nec pharetra augue.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*SECTION START*/}
            <section>
                <div className="ed-res-bg">
                    <div className="container com-sp pad-bot-70 ed-res-bg">
                        <div className="row">
                            <div className="cor about-sp">
                                <div className="ed-rsear">
                                    <div className="ed-rsear-in">
                                        <ul>
                                            {trainingData.map((data, i) => {
                                                { var j = i % colors.length }
                                                return (
                                                    <li key={i}>
                                                        <Link to={`/training_details/${data._id}`}>
                                                            <div className="ed-rese-grid">
                                                                <div className="ed-rsear-img">
                                                                    <div class="training-list-image mr-3 rounded-circle text-white" style={{ backgroundColor: colors[j] }}>
                                                                        {format(parseISO(data.Date), 'dd MMM')}
                                                                        <br />
                                                                        {format(parseISO(data.Date), 'yyyy')}
                                                                    </div>
                                                                </div>
                                                                <div className="ed-rsear-dec">
                                                                    <h4><a href="#">{data.Training_Name}</a></h4>
                                                                    <a href="#">{data.Description.slice(0, 27)}.... <span>read more</span></a>
                                                                    {/* <div className="ed-flag"><img src="images/icon/flag-1.png" alt="error" /></div> */}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="ed-about-sec1">
                                    <div className="col-md-6" />
                                    <div className="col-md-6" />
                                </div>
                            </div>
                        </div>
                        <div className="pg-pagina">
                            <ul className="pagination">
                                <li className="prePage waves-effect">
                                    <Link onClick={() => setPageNumber(pageNumber - 1)}><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
                                </li>

                                {pagination.map((data) => <>{data}</>)}

                                <li className="nextPage waves-effect">
                                    <Link onClick={() => setPageNumber(pageNumber + 1)}><i class="fa fa-angle-right" aria-hidden="true"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/*SECTION END*/}
        </div>

    )
}

export default Training