import React, { useEffect, useState } from 'react';
import "./css/training.css";
import { useParams } from 'react-router-dom';
import { format, parseISO, subDays } from 'date-fns';
import axios from 'axios';

function Training_Details() {
    const { id } = useParams();
    const apiurl = process.env.REACT_APP_API_URI;
    const [trainingData, setTrainingData] = useState([]);

    // console.log(trainingData.Start_date);
    // const dt = "2023-08-17T16:09:43.390Z";
    // console.log(format(parseISO(trainingData.Start_date), 'yyyy-MM-dd'));

    // Get the current page data from the server
    const handelTrainingData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/training/getOne/${id}`)
                .then((result) => {
                    setTrainingData(result.data);

                    const content = document.getElementById(result.data._id);
                    content.innerHTML = format(parseISO(result.data.End_date), 'dd') - format(parseISO(result.data.Start_date), 'dd');
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handelTrainingData();
    }, [])

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn">
                            <h1>Training Details</h1>
                            <p>Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut pellentesque maximus lacus, nec pharetra augue.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*SECTION START*/}
            <section>
                <div className="container" style={{ margin: '8rem auto' }}>
                    <table className="table table-light table-hover shadow border" style={{ height: '38rem' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div><strong>Training Name: </strong></div>
                                </td>
                                <td><div>{trainingData.Training_Name}</div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Training Start Date: </strong></div>
                                </td>
                                <td><div>{trainingData.Start_date}</div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Training End Date: </strong></div>
                                </td>
                                <td><div>{trainingData.End_date}</div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Duration: </strong></div>
                                </td>
                                <td>
                                    <div>
                                        <span id={trainingData._id}></span> Days
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Name of participant: </strong></div>
                                </td>
                                <td><div>{trainingData.Name_Participant}</div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Number of participant: </strong></div>
                                </td>
                                <td><div>{trainingData.Number_participant}</div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Description: </strong></div>
                                </td>
                                <td>
                                    <div>{trainingData.Description}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            {/*SECTION END*/}
        </div>

    )
}

export default Training_Details