import React from 'react'

function Contact_Us() {
    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Contact Us</h1>
                            <p>Have Questions? Contact Us</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*SECTION START*/}
            <section>
                <div className="container com-sp pad-bot-70">
                    <div className="row">
                        <div className="cor about-sp">
                            <div className="pg-contact d-flex justify-content-evenly">
                                {/* <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con1">
                                    <h2>Educate <span>Master</span></h2>
                                    <p>We Provide Outsourced Software Development Services To Over 50 Clients From 21 Countries.</p>
                                </div> */}
                                <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con1">
                                    <h4>Address</h4>
                                    <p>DIET, BTI ROAD, MAHASAMUND-493445
                                        </p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con3">
                                    <h4>CONTACT INFO:</h4>
                                    <p> <a href="tel://07723-224781" className="contact-icon"></a>
                                        <br /> <a href="tel://0099999999" className="contact-icon">Tel://07723-224781</a>
                                        <br /> <a href="mailto:dietmahasamund@gmail.com" className="contact-icon">Email: dietmahasamund@gmail.com</a> </p>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*SECTION END*/}
            <section id="map">
                <div className="row contact-map">
                    {/* IFRAME: GET YOUR LOCATION FROM GOOGLE MAP */}
                    <iframe src="https://www.google.com/maps/embed?pb=pb=!1m18!1m12!1m3!1d1861.1284000312642!2d82.08255577792781!3d21.10232570917991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28a57f8475f81d%3A0x5a9ddd6df0c31452!2sDIET%20Mahasamund!5e0!3m2!1sen!2sin!4v1694188883400!5m2!1sen!2sin" allowFullScreen />


                    <div className="container">
                        <div className="overlay-contact footer-part footer-part-form">
                            <div className="map-head">
                                <p>Send Us Now</p>
                                <h2>GetIn Touch</h2> <span className="footer-ser-re">Service Request Form</span> </div>
                            {/* ENQUIRY FORM */}
                            <form id="contact_form" name="contact_form" action="http://rn53themes.net/themes/demo/education-master/send.php">
                                <ul>
                                    <li className="col-md-6 col-sm-6 col-xs-12 contact-input-spac">
                                        <input type="text" id="f1" defaultValue name="f1" placeholder="Name" required /> </li>
                                    <li className="col-md-6 col-sm-6 col-xs-12 contact-input-spac">
                                        <input type="text" id="f2" defaultValue name="f2" placeholder="Phone" required /> </li>
                                    <li className="col-md-6 col-sm-6 col-xs-12 contact-input-spac">
                                        <input type="text" id="f3" defaultValue name="f3" placeholder="City" required /> </li>
                                    <li className="col-md-6 col-sm-6 col-xs-12 contact-input-spac">
                                        <input type="text" id="f4" defaultValue name="f4" placeholder="Country" required /> </li>
                                    <li className="col-md-12 col-sm-12 col-xs-12 contact-input-spac">
                                        <textarea id="f5" name="f5" required defaultValue={""} />
                                    </li>
                                    <li className="col-md-6">
                                        <input type="submit" defaultValue="SUBMIT" /> </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Contact_Us