import React, { useEffect, useState } from 'react';
import "./css/notice.css";
import noticeJson from "./Json/notice.json";
import { format, parseISO, addDays } from 'date-fns';
import axios from "axios";

function Notice() {
    const colors = ["#0D6EFD", "#6C757D", "#198754", "#DC3545", "#FFC107", "#0DCAF0", "#D63384"];
    const [notice, setNotice] = useState([]);

    const url = process.env.REACT_APP_API_URI;

    const handleNotice = async () => {
        try {
            await axios.get(`${url}diet/notice/getOne`)
                .then((res) => {
                    const data = res.data.data;
                    setNotice(data)
                })
        } catch (error) {
            console.log(error)
        }
    }

    console.log(notice)

    useEffect(() => {
        handleNotice();
    }, [])

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Notice</h1>
                            <p>Stay Updated: Essential Notices, Timely Reminders, and Important Announcements.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="noticeSection py-5">
                <div className="container py-5">
                    <table className="table table-striped shadow border">
                        <thead>
                            <tr>
                                <th colSpan="2">
                                    <div className="p-2">
                                        <h4>Recent</h4>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {notice.map((data, i) => {
                                { var j1 = i % colors.length }
                                return format((new Date()), 'yyyy-MM-dd') <= format(addDays(parseISO(data.noticeDate), 7), 'yyyy-MM-dd')
                                    ?
                                    <tr key={data._id}>
                                        {console.log(i)}
                                        <td>
                                            <div class="notice-list-image mr-3 rounded-circle text-white" style={{ backgroundColor: colors[j1] }}>
                                                {format(parseISO(data.noticeDate), 'dd')}
                                                <br />
                                                {format(parseISO(data.noticeDate), 'MMM')}
                                            </div>
                                        </td>
                                        <td>
                                            <h5 className="text-uppercase"><strong><a href="#">{data.noticeHeading}</a></strong></h5>
                                            <div>{data.noticeDesc}</div>
                                        </td>
                                    </tr> : <></>
                            })}
                        </tbody>
                    </table>
                    <table className="table table-striped shadow border">
                        <thead>
                            <tr>
                                <th colSpan="2">
                                    <div className="p-2">
                                        <h4>Earlier</h4>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {notice.map((data, i) => {
                                { var j2 = i % colors.length }
                                return format((new Date()), 'yyyy-MM-dd') > format(addDays(parseISO(data.noticeDate), 7), 'yyyy-MM-dd')
                                    ?
                                    <tr key={data._id}>
                                        <td>
                                            <div class="notice-list-image mr-3 rounded-circle text-white" style={{ backgroundColor: colors[j2] }}>
                                                {format(parseISO(data.noticeDate), 'dd')}
                                                <br />
                                                {format(parseISO(data.noticeDate), 'MMM')}
                                            </div>
                                        </td>
                                        <td>
                                            <h5 className="text-uppercase"><strong><a href="#">{data.noticeHeading}</a></strong></h5>
                                            <div>{data.noticeDesc}</div>
                                        </td>
                                    </tr>
                                    : <></>
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}

export default Notice