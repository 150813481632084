import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function All_Courses() {
    const apiurl = process.env.REACT_APP_API_URI;
    const [courseData, setCourseData] = useState([]);
    const [pageNumber, setPageNumber] = useState();
    const [pagination, setPagination] = useState([]);

    // It use for show number of pages
    const handelAllData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/course/getOne`)
                .then((result) => {
                    let count = result.data.length / 8;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [...current, <li className="waves-effect middlePageNo" key={i} onClick={(e) => setPageNumber(i + 1)}><Link>{i + 1}</Link></li>]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Get the current page data from the server
    const handelCourseData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/course/get/${pageNumber}`)
                .then((result) => {
                    setCourseData(result.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // when changing the page number then it call
    useEffect(() => {
        handelCourseData();

        // Hide and show previous button
        const prePage = document.getElementsByClassName("prePage");
        pageNumber > 1 ? prePage[0].classList.remove("hidden") : prePage[0].classList.add("hidden");

        // Hide and show next button
        const nextPage = document.getElementsByClassName("nextPage");
        if (pagination.length) {
            pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
        }
    }, [pageNumber]);

    useEffect(() => {
        setPageNumber(1)
        handelAllData();
    }, []);

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>All Courses</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pop-cour">
                <div className="container com-sp pad-bot-70">
                    <div className="row">
                        {courseData.map((data, index) => {
                            return (
                                <div className="col-md-6">
                                    <div>
                                        {/*POPULAR COURSES*/}
                                        <div className="home-top-cour">
                                            {/*POPULAR COURSES IMAGE*/}
                                            <div className="col-md-4">
                                                <img src={`${apiurl}/${data.Image}`} alt="error" style={{ height: '15rem' }} />
                                            </div>
                                            {/*POPULAR COURSES: CONTENT*/}
                                            <div className="col-md-8 home-top-cour-desc">
                                                <Link to="/course_details">
                                                    <h3>{data.Heading}</h3>
                                                </Link>
                                                <h4>
                                                    {
                                                        data.Subjects.split(",").map((sname, i) => {
                                                            return (
                                                                data.Subjects.split(",").length == i + 1 ? sname : sname + ", "
                                                            )
                                                        })
                                                    }
                                                </h4>
                                                <p>
                                                    {110 >= data.Desc.length ? data.Desc : data.Desc.slice(0, 110) + "..."}
                                                </p>
                                                {/* <span className="home-top-cour-rat">4.2</span> */}
                                                <div className="hom-list-share">
                                                    <ul>
                                                        <li><Link className="hom-list-share" to={`/course_details/${data._id}`}>Read More</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="pg-pagina">
                        <ul className="pagination">
                            <li className="prePage waves-effect">
                                <Link onClick={() => setPageNumber(pageNumber - 1)}><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
                            </li>

                            {pagination.map((data) => <>{data}</>)}

                            <li className="nextPage waves-effect">
                                <Link onClick={() => setPageNumber(pageNumber + 1)}><i class="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default All_Courses