import React, { useEffect, useState } from "react";
import "./css/slider.css";
import "./css/scrollable.css";
import "./css/home.css"
import { Link } from "react-router-dom";
import axios from "axios";
import MessageSlider from "./MessageSlider";
function Home() {
  const url = process.env.REACT_APP_API_URI;

  const [event, setEvents] = useState([]);
  const [notice, setNotice] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [slider, setSlider] = useState([]);
  const [honorablePerson, setHonorablePerson] = useState([]);

  const handleNotice = async () => {
    try {
      if (!hasMoreData || loading) return;

      setLoading(true);

      setTimeout(() => {
        axios.get(`${url}diet/notice/get/${page}`).then((result) => {
          const data = result.data;
          setNotice([...notice, ...data]);
          setLoading(false);
          setPage(page + 1);
          setHasMoreData(data.length > 0);
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  const handleEvent = async () => {
    try {
      await axios.get(`${url}diet/event/getOne`).then((res) => {
        const data = res.data;
        setEvents(data);
        data.map((data) => {
          // console.log(data.Desc);
          onLoadContent(data.Desc, data._id);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInfiteScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const scrollThreshold = 50;
    console.log(
      "scrollTop=>" +
      scrollTop +
      "clientHeight=>" +
      clientHeight +
      "scrollHeight=>" +
      scrollHeight
    );
    if (scrollTop + clientHeight + scrollThreshold >= scrollHeight) {
      handleNotice();
    }
  };

  const onLoadContent = async (descText, divId) => {
    await fetch(`${url}${descText}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const regex = /(<([^>]+)>)/gi;
        const newString = data.replace(regex, " ");
        const content = document.getElementById(divId);
        content.innerHTML = newString;
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleSlider = async () => {
    try {
      await axios.get(`${url}diet/slider/get`)
        .then((res) => {
          const data = res.data.data;
          setSlider(data);
        })
    } catch (error) {
      console.log(error);
    }
  }

  const getHonorablePerson = async () => {
    try {
      await axios.get(`${url}diet/honorablePerson/get`)
        .then((res) => {
          setHonorablePerson(res.data.data);
        })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getHonorablePerson();
  }, []);

  useEffect(() => {
    handleNotice();
    handleEvent();
    handleSlider();
  }, [page]);

  return (
    <div>
      {/* SLIDER */}
      <section>
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            {
              slider.map((data, index) =>
                index === 0 ? (<div className="item slider1 active customeSlider" >
                  <img src={`${url}${data.sliderImage}`} alt="error" style={{ minHeight: "600px" }} />
                  {/* <div className="carousel-caption slider-con customeSlider"> */}
                  {/* <h2>Welcome to <span>District Institute of Education and Training Mahasamund</span></h2> */}
                  {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                      suffered alteration in some form</p>
                                  <a href="#" className="bann-btn-1">All Courses</a><a href="#" className="bann-btn-2">Read More</a> */}
                  {/* </div> */}
                </div>) : (<div className="item customeSlider" >
                  <img src={`${url}${data.sliderImage}`} style={{ minHeight: "600px" }} alt="error" />
                  {/* <div className="carousel-caption slider-con customeSlider"> */}
                  {/* <h2>Welcome to <span>District Institute of Education and Training Mahasamund</span></h2> */}
                  {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                    suffered alteration in some form</p>
                                <a href="#" className="bann-btn-1">All Courses</a><a href="#" className="bann-btn-2">Read More</a> */}
                  {/* </div> */}
                </div>)
              )
            }


          </div>
          <a
            className="left carousel-control "
            href="#myCarousel"
            data-slide="prev"
          >
            <i className="fa fa-chevron-left slider-arr change-carousel" />
          </a>
          <a
            className="right carousel-control "
            href="#myCarousel"
            data-slide="next"
          >
            <i className="fa fa-chevron-right slider-arr change-carousel" />
          </a>
        </div>
      </section>

      {/* QUICK LINKS */}


      {/* DISCOVER MORE */}
      <section>
        <div className="container com-sp pad-bot-70">
          <div className="row">
            <div className="con-title">
              <h2>
                Eminent Voices: Celebrating Leaders,{" "}
                <span>and Changemakers</span>
              </h2>
              <p>
                Welcome to DEIT's official website, a platform dedicated to
                fostering knowledge, innovation, and collaboration. As an
                institution committed to academic excellence and societal
                advancement, we are honored to share with you the voices and
                insights of individuals who have made significant contributions
                to the betterment of our society.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="ed-course">
              {honorablePerson.map((data, i) => {
                return (
                  <div className="col-md-3 col-sm-4 col-xs-12 mb-5">
                    <div className="" style={{ height: "24rem" }}>
                      <a className="course-overlay" href="#" style={{ cursor: 'default' }}>
                        <img
                          src={`${url}/${data.image}`}
                          alt="error"
                          style={{ height: "100%" }}
                        />
                      </a>
                    </div>
                    <div style={{ textAlign: "center", lineHeight: "24px" }}>
                      {data.name}
                      <br />
                      {data.designation}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      {/* UPCOMING EVENTS */}
      <section className="pop-cour">
        <div className="container com-sp pad-bot-70">
          <div className="row">
            <div className="con-title">
              <h2>
                Notice and <span>Events</span>
              </h2>
              <p>
                Explore the latest updates, upcoming happenings, and essential
                notifications to keep yourself connected and informed with DIET
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <div className="ho-ev-latest ho-ev-latest-bg-1">
                <div className="ho-lat-ev">
                  <h4>Upcoming Event</h4>
                  <p>
                    Join Us for Engaging Gatherings, Insightful Discussions, and
                    Memorable Experiences
                  </p>
                </div>
              </div>
              <div className="ho-event ho-event-mob-bot-sp scrollable-content">
                {event.map((data) => {
                  return (
                    <ul key={data._id}>
                      <li>
                        <div className="ho-ev-date">
                          <span>07</span>
                          <span>jan,2018</span>
                        </div>
                        <div className="ho-ev-link">
                          <a href="events.html">
                            <Link to={`/event_details/${data._id}`}>
                              <h4>{data.Event_Name}</h4>
                            </Link>
                          </a>
                          <p id={data._id}></p>
                          <span>9:15 am to 5:00 pm</span>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="ho-ev-latest ho-ev-latest-bg-2">
                <div className="ho-lat-ev">
                  <Link to="/notice">
                    <h4>Notice</h4>
                  </Link>
                  <p>
                    Stay Updated: Essential Notices, Timely Reminders, and
                    Important Announcements.
                  </p>
                </div>
              </div>
              <div
                className="ho-event ho-event-mob-bot-sp scrollable-content"
                onScroll={handleInfiteScroll}
              >
                {notice.map((data) => {
                  return (
                    <ul key={data._id}>
                      <li>
                        {/* <div className="ho-ev-img"><img src="images/event/1.jpg" alt="error" /> */}
                        {/* </div> */}
                        <div className="ho-ev-link">
                          <a
                            href={`https://dietmahasamund.org.in:4020/${data.noticePdf}`}
                          >
                            <p
                              style={{
                                color: "red",
                                textDecoration: "underline",
                              }}
                            >
                              {data.noticeDesc}
                            </p>
                          </a>
                        </div>
                      </li>
                    </ul>
                  );
                })}

                {loading && (
                  <div class="spinner-border text-primary " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-md-4">
                            <div className="ho-ev-latest ho-ev-latest-bg-3">
                                <div className="ho-lat-ev">
                                    <h4>Notifications</h4>
                                    <p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
                                </div>
                            </div>
                            <div className="ho-event ho-event-mob-bot-sp">
                                <ul>
                                    <li>
                                        <a href="#" className="text-primary">Almost before we knew it, we had left the ground</a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-primary">Almost before we knew it, we had left the ground</a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-primary">Almost before we knew it, we had left the ground</a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-primary">Almost before we knew it, we had left the ground</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>

      {/* slider message  */}

      <div className="con-title">
        <h2>
          Message & <span>Testimonial</span>
        </h2>
        <MessageSlider />
      </div>

      {/* NEWS AND EVENTS */}
      {/* <section>
        <div className="container com-sp pad-bot-70">
          <div className="row">
            <div className="con-title">
              <h2>
                Photos and <span>Videos</span>
              </h2>
              <p></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="bot-gal h-gal ho-event-mob-bot-sp">
                <h4>Photo Gallery</h4>
                <ul>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/8.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/9.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/10.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/11.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/1.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/2.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/3.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/4.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/5.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/6.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/7.jpg"
                      alt="error"
                    />
                  </li>
                  <li>
                    <img
                      className="materialboxed"
                      data-caption="Education master image captions"
                      src="images/ami/8.jpg"
                      alt="error"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bot-gal h-vid ho-event-mob-bot-sp">
                <h4>Video Gallery</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <iframe
                      src="https://www.youtube.com/embed/2WqFtiR4-F4?autoplay=0&showinfo=0&controls=1"
                      allowFullScreen
                    />
                    <h5>Maecenas sollicitudin lacinia</h5>
                    <p>
                      Maecenas finibus neque a tellus auctor mattis. Aliquam
                      tempor varius ornare. Maecenas dignissim leo leo, nec
                      posuere purus finibus vitae.
                    </p>
                    <p>
                      Quisque vitae neque at tellus malesuada convallis.
                      Phasellus in lectus vitae ex euismod interdum non a lorem.
                      Nulla bibendum. Curabitur mi odio, tempus quis risus
                      cursus.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <iframe
                      src="https://www.youtube.com/embed/2WqFtiR4-F4?autoplay=0&showinfo=0&controls=1"
                      allowFullScreen
                    />
                    <h5>Maecenas sollicitudin lacinia</h5>
                    <p>
                      Maecenas finibus neque a tellus auctor mattis. Aliquam
                      tempor varius ornare. Maecenas dignissim leo leo, nec
                      posuere purus finibus vitae.
                    </p>
                    <p>
                      Quisque vitae neque at tellus malesuada convallis.
                      Phasellus in lectus vitae ex euismod interdum non a lorem.
                      Nulla bibendum. Curabitur mi odio, tempus quis risus
                      cursus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Home;
