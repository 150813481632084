import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./admin_css/admin_header.css";
import { Link } from 'react-router-dom';

function Admin_Header() {

    const options = [
        {
            name: 'Enable both scrolling & backdrop',
            scroll: true,
            backdrop: true,
        },
    ];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    return (
        <>
            {/* Bootstrap element */}
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Button variant="primary" onClick={toggleShow} style={{ position: 'absolute', left: '8px' }} >
                        <i class="fa-solid fa-grip-lines"></i>
                    </Button>
                    {/* <Navbar.Brand href="#home">Navbar with text</Navbar.Brand> */}
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <h4 className="me-3"><a href="#">Navbar With Text</a></h4>
                        </Navbar.Text>
                        <Navbar.Text>
                            Signed in as: <a href="#login">Sign In</a>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Side Bar */}

            <Offcanvas show={show} onHide={handleClose} {...options}>
                <Offcanvas.Header closeButton className="shadow">
                    <Offcanvas.Title><h3><strong>Side Menu List</strong></h3></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="listBody p-4">
                        <div class="container">
                            <ul>
                                <li><Link to="#">Parentheses</Link></li>
                                <li><Link to="#">Exponents</Link></li>
                                <li><Link to="#">Multiplication</Link></li>
                                <li><Link to="#">Division</Link></li>
                                <li><Link to="#">Addition</Link></li>
                                <li><Link to="#">Subtraction</Link></li>
                            </ul>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Admin_Header