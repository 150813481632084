import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Contact_Us from './components/Contact_Us';
import Dashboard from './components/admin/Dashboard';
import PageNotFound from './components/PageNotFound';
import All_Courses from './components/All_Courses';
import Course_Details from './components/Course_Details';
import Faculties from './components/Faculties';
import Events from './components/Events';
import Event_Details from './components/Event_Details';
import Training from './components/Training';
import Training_Details from './components/Training_Details';
import Faculty_Details from './components/Faculty_Details';
import Gallery from './components/Gallery';
import Notice from './components/Notice';
import Admin_Header from './components/admin/Admin_Header';

class App extends Component {
  render() {
    const urlPath = window.location.pathname.split("/")[1];
    console.log(localStorage.getItem("userID"));
    return (
      <Router>
        {
          "admin" === urlPath ?
            <>
              <Admin_Header />
              <Routes>
                <Route path="/admin" Component={Dashboard} />
                <Route path="*" Component={PageNotFound} />
              </Routes>
            </>
            :
            <>
              <Header />
              <Routes>
                <Route path="/" Component={Home} />
                <Route path="/about/:id?" Component={About} />
                <Route path="/contact" Component={Contact_Us} />
                <Route path="/courses" Component={All_Courses} />
                <Route path="/course_details/:id" Component={Course_Details} />
                <Route path="/faculties" Component={Faculties} />
                <Route path="/faculty_details/:id" Component={Faculty_Details} />
                <Route path="/events" Component={Events} />
                <Route path="/event_details/:id" Component={Event_Details} />
                <Route path="/training" Component={Training} />
                <Route path="/training_details/:id" Component={Training_Details} />
                <Route path="/gallery" Component={Gallery} />
                <Route path="/notice" Component={Notice} />
                <Route path="*" Component={PageNotFound} />
              </Routes>
              <Footer />
            </>
        }
      </Router>
    );
  }
}

export default App;
