import React, { useEffect, useState } from 'react';
import "./css/gallery.css";
import axios from 'axios';
import { Link } from "react-router-dom";
import galleryDetail from "./Json/gallery.json";
import ModalImage from "react-modal-image";

function Gallery() {

    const apiurl = process.env.REACT_APP_API_URI;
    const [toggle, setToggle] = useState(true);
    const [photoGalleryData, setPhotoGalleryData] = useState([]);
    const [videoGalleryData, setVideoGalleryData] = useState([]);
    const [pageNumber, setPageNumber] = useState();
    const [pagination, setPagination] = useState([]);

    // It use for show number of pages
    const handelGalleryPageNumber = async () => {
        try {
            await axios
                .get(`${apiurl}diet/gallery/length/`)
                .then((result) => {
                    let count = result.data / 8;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [
                            ...current,
                            <li
                                className="waves-effect middlePageNo"
                                key={i}
                                onClick={(e) => setPageNumber(i + 1)}
                            >
                                <Link>{i + 1}</Link>
                            </li>,
                        ]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    // It use for show number of pages
    const handelVideoPageNumber = async () => {
        try {
            await axios
                .get(`${apiurl}diet/videoGallery/length/`)
                .then((result) => {
                    let count = result.data / 8;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [
                            ...current,
                            <li
                                className="waves-effect middlePageNo"
                                key={i}
                                onClick={(e) => setPageNumber(i + 1)}
                            >
                                <Link>{i + 1}</Link>
                            </li>,
                        ]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    // Get the current page data from the server
    const handelPhotoGalleryData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/gallery/length/${pageNumber}`)
                .then((result) => {
                    setPhotoGalleryData(result.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    // Get the current page data from the server
    const handelVideoGalleryData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/videoGallery/length/${pageNumber}`)
                .then((result) => {
                    setVideoGalleryData(result.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    // when changing the page number then it call
    useEffect(() => {
        handelPhotoGalleryData();
        handelVideoGalleryData();

        // Hide and show previous button
        const prePage = document.getElementsByClassName("prePage");
        pageNumber > 1 ? prePage[0].classList.remove("hidden") : prePage[0].classList.add("hidden");

        // Hide and show next button
        const nextPage = document.getElementsByClassName("nextPage");
        if (pagination.length) {
            pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
        }
    }, [pageNumber]);

    useEffect(() => {
        setPagination([]);
        setPageNumber(1);
        toggle ? handelGalleryPageNumber() : handelVideoPageNumber()
    }, [toggle])

    const filterSelection = (val) => {
        setToggle(!toggle);
        const tabs = document.querySelector(".allTabs").children;
        for (let i = 0; i < tabs.length; i++) {
            const element = tabs[i];
            const clssname = element.className.split(" ");

            if (val === "all") {
                element.classList.remove("hidden");
            } else {
                element.classList.add("hidden");
                clssname.forEach(cname => {
                    if (cname === val) {
                        element.classList.remove("hidden");
                    }
                });
            }
        }
    }

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Photo & Video Gallery</h1>
                            <p>Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut pellentesque maximus lacus, nec pharetra augue.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="m-4 text center">
                    <button className='btn mx-3' onClick={() => filterSelection('photoGallery')}>Photo Gallery</button>
                    <button className='btn mx-3' onClick={() => filterSelection('videoGallery')}>Video Gallery</button>
                </div>
            </div>

            {/*SECTION START*/}
            <section>
                <div className="ed-res-bg">
                    <div className="container com-sp pad-bot-70 ed-res-bg">
                        <div className="row allTabs">
                            <div className="h-gal ed-pho-gal photoGallery">
                                <ul>
                                    {
                                        photoGalleryData.map((data, index) => {
                                            return (
                                                <li key={index}>
                                                    <ModalImage
                                                        small={`${apiurl}/${data.galleryImage}`}
                                                        large={`${apiurl}/${data.galleryImage}`}
                                                        alt={data.galleryDesc}
                                                        className="modalImage"
                                                    />
                                                    <div className="gdesc">
                                                        <div className="gallery-description">
                                                            {
                                                                data.galleryDesc.length > data.galleryDesc.slice(0, 26).length ? data.galleryDesc.slice(0, 23) + "..." : data.galleryDesc
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {/* {
                                        galleryDetail.map((data, index) => {
                                            return (
                                                <li key={index}>
                                                    <img className="materialboxed" data-caption={data.galleryDesc} src={data.galleryImage} alt="error" />
                                                    <div className="gdesc">
                                                        <div className="gallery-description">
                                                            {
                                                                data.galleryDesc.length > data.galleryDesc.slice(0, 26).length ? data.galleryDesc.slice(0, 23) + "..." : data.galleryDesc
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    } */}

                                </ul>
                            </div>
                            <div className="row videoGallery hidden">
                                {videoGalleryData.map((data, index) => {
                                    return (
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                                            <div class="h-vid ho-event-mob-bot-sp">
                                                {data.videoLink.split("/")[3].split("?")[0] === "watch"
                                                    ? <iframe src={`https://www.youtube.com/embed/${data.videoLink.split("/")[3].split("=")[1]}?autoplay=0&amp;showinfo=0&amp;controls=1`} allow="fullscreen"></iframe>
                                                    : <iframe src={`https://www.youtube.com/embed/${data.videoLink.split("/")[3]}?autoplay=0&amp;showinfo=0&amp;controls=1`} allow="fullscreen"></iframe>
                                                }
                                                <p>{data.VideoDesc}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="pg-pagina">
                            <ul className="pagination">
                                <li className="prePage waves-effect">
                                    <Link onClick={() => setPageNumber(pageNumber - 1)}><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
                                </li>

                                {pagination.map((data) => <>{data}</>)}

                                <li className="nextPage waves-effect">
                                    <Link onClick={() => setPageNumber(pageNumber + 1)}><i class="fa fa-angle-right" aria-hidden="true"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/*SECTION END*/}
        </div>

    )
}

export default Gallery