import React from "react";
import { Link } from "react-router-dom";
import "./css/header.css";

function Mobile_Menu() {
  const handleToggle = (e) => {
    // console.log(e.target.id);
    const div = document.getElementById("mySlider");
    console.log(div);
    div.classList.remove("ed-mm-act");
  };

  return (
    <section>
      <div className="ed-mob-menu">
        <div className="ed-mob-menu-con">
          <div className="ed-mm-left">
            <div className="wed-logo">
              <Link to="/">
                <img src="images/diet_logo.png" alt="error" />
                <strong className="logoName">
                  जिला शिक्षा एवं प्रशिक्षण संस्थान महासमुंद छ. ग.
                </strong>
              </Link>
            </div>
          </div>
          <div className="ed-mm-right">
            <div className="ed-mm-menu">
              <div className="ed-micon">
                <i className="fa fa-bars" />
              </div>
              <div className="ed-mm-inn" id="mySlider">
                <div className="ed-mi-close">
                  <i className="fa fa-times" />
                </div>
                <h4>Admin Account</h4>
                <ul>
                  <li>
                    <a href="#!" data-toggle="modal" data-target="#modal1">
                      Sign In
                    </a>
                  </li>
                  <li>
                    <a href="#!" data-toggle="modal" data-target="#modal2">
                      Register
                    </a>
                  </li>
                </ul>
                <h4>All Pages</h4>
                <ul onClick={(e) => handleToggle(e)}>
                  <li>
                    <Link to="/" id="1">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/courses">All courses</Link>
                  </li>
                  <li>
                    <Link to="/faculties">Faculties</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/training">Training</Link>
                  </li>
                  <li>
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li>
                    <Link to="/notice">Notice</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mobile_Menu;
