import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "./css/faculty.css";
import axios from 'axios';

function Faculties() {
    const apiurl = process.env.REACT_APP_API_URI;
    const [facultyData, setFacultyData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagination, setPagination] = useState([]);

    // It use for show number of pages
    const handelAllData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/faculty/getOne`)
                .then((result) => {
                    let count = result.data.data.length / 6;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [...current, <li className="waves-effect middlePageNo" key={i} onClick={(e) => setPageNumber(i + 1)}><Link>{i + 1}</Link></li>]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Get the current page data from the server
    const handelFacultyData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/faculty/get/${pageNumber}`)
                .then((result) => {
                    setFacultyData(result.data);
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // when changing the page number then it call
    useEffect(() => {
        handelFacultyData();

        // Hide and show previous button
        const prePage = document.getElementsByClassName("prePage");
        pageNumber > 1 ? prePage[0].classList.remove("hidden") : prePage[0].classList.add("hidden");

        // Hide and show next button
        const nextPage = document.getElementsByClassName("nextPage");
        if (pagination.length) {
            pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
        }

    }, [pageNumber]);

    useEffect(() => {
        handelAllData();
        // setPageNumber(1)
    }, [])

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Faculties</h1>
                            <p>Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut pellentesque maximus lacus, nec pharetra augue.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*SECTION START*/}
            <section id="faculty_card" style={{ padding: '10rem 0' }}>
                <div className="container py-5" >
                    <div className="row">
                        {facultyData.map((data, i) => {
                            return (
                                <div className="col-12 col-sm-6 col-md-4" key={i}>
                                    <div className="our-team">
                                        <div className="picture">
                                            {/* <img className="img-fluid" src="https://fastly.picsum.photos/id/1027/130/130.jpg?hmac=3g0qnkp9DtUw57HJkMRT0RCmIKgnpu1xPvwL20HdvMs" /> */}
                                            <img className="img-fluid" src={`${apiurl}/${data.Image}`} style={{ width: '130px', height: '130px' }} />
                                        </div>
                                        <div className="team-content">
                                            <h3 className="name text-capitalize">{data.Name}</h3>
                                            <h4 className="title">{data.Designation}</h4>
                                        </div>
                                        <ul className="social">
                                            <li>
                                                <Link to={`/faculty_details/${data._id}`} aria-hidden="true"><strong>View Profile</strong></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="pg-pagina">
                    <ul className="pagination">
                        <li className="prePage waves-effect">
                            <Link onClick={() => setPageNumber(pageNumber - 1)}><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
                        </li>

                        {pagination.map((data) => <>{data}</>)}

                        <li className="nextPage waves-effect">
                            <Link onClick={() => setPageNumber(pageNumber + 1)}><i class="fa fa-angle-right" aria-hidden="true"></i></Link>
                        </li>
                    </ul>
                </div>
            </section>
            {/*SECTION END*/}
        </div>

    )
}

export default Faculties