import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Mobile_Menu from "./Mobile_Menu";
import "./css/header.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/userSlice";

const customeButton = {
  marginBottom: "2%",
  fontSize: "15px",
  backgroundColor: "#002147",
  width: "100%",
  hieght: "35px",
  margin: "5px",
  textAlign: "center",
  // lineHeight: "75px",
};

const customeUser = {
  marginLeft: "12px",
  marginTop: "8px",
  fontSize: "28px",
  color: "#a5a46c",
  cursor: "pointer",
};

function Header() {
  const apiurl = process.env.REACT_APP_API_URI;
  const [aboutData, setAboutData] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const [pagination, setPagination] = useState([]);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  // console.log(currentUser);

  // It use for show number of pages
  const handelPageNumber = async () => {
    try {
      await axios
        .get(`${apiurl}diet/about/length/`)
        .then((result) => {
          let count = result.data / 4;
          if (count <= 1) {
            // Hide and show next button
            const nextPage = document.getElementsByClassName("aboutNextPage");
            nextPage[0].classList.add("hidden")
          }
          for (let i = 0; i < count; i++) {
            setPagination((current) => [
              ...current,
              <li
                className="waves-effect middlePageNo"
                key={i}
                onClick={(e) => setPageNumber(i + 1)}
              >
                <Link>{i + 1}</Link>
              </li>,
            ]);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get the current page data from the server
  const handelAboutData = async () => {
    try {
      await axios
        .get(`${apiurl}diet/about/length/${pageNumber}`)
        .then((result) => {
          setAboutData(result.data);
          result.data.map((data) => {
            onLoadContent(data.desc, data._id);
          });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Convert text file to text format
  const onLoadContent = async (descText, divId) => {
    await fetch(`${apiurl}${descText}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const regex = /(<([^>]+)>)/gi;
        const newString = data.replace(regex, " ");
        const content = document.getElementById(divId);
        content.innerHTML =
          newString.length > 160 ? newString.slice(0, 160) + "..." : newString;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleLogin(){
    window.location = `https://admin.dietmahasamund.org.in/`
  }

  // when changing the page number then it call
  useEffect(() => {
    handelAboutData();

    // Hide and show previous button
    const prePage = document.getElementsByClassName("aboutPrePage");
    pageNumber > 1
      ? prePage[0].classList.remove("hidden")
      : prePage[0].classList.add("hidden");

    // Hide and show next button
    const nextPage = document.getElementsByClassName("aboutNextPage");
    if (pagination.length) {
      pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
    }
  }, [pageNumber]);

  useEffect(() => {
    handelPageNumber();
    setPageNumber(1);
  }, []);

  return (
    <div>
      {/* MOBILE MENU */}
      <Mobile_Menu />

      {/*HEADER SECTION*/}
      <section>
        {/* TOP BAR */}
        <div className="ed-top">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ed-com-t1-left">
                  <ul>
                    <li>
                      <a href="#">Contact: DIET Mahasamund</a>
                    </li>
                    <li>
                      <a href="#">Phone: +101-1231-1231</a>
                    </li>
                  </ul>
                </div>
                <div className="ed-com-t1-right">
                  <ul>
                    <li>
                      {!currentUser ? (
                        <div
                          className="btn  btn-secondary"
                          style={{
                            marginBottom: "2%",
                            fontSize: "15px",
                            backgroundColor: "#002147",
                          }}
                          // data-toggle="modal"
                          // data-target="#modal1"
                          onClick={handleLogin}
                        >
                          Sign In
                        </div>
                      ) : (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            className="btn  btn-primary"
                            style={customeButton}
                            onClick={() => dispatch(logout())}
                          >
                            logout
                          </div>
                          <div style={customeUser}>
                            <i class="fa-regular fa-user"></i>
                          </div>
                        </div>
                      )}
                    </li>
                    {/* <li><a href="#!" data-toggle="modal" data-target="#modal2">Sign Up</a>
                                        </li> */}
                  </ul>
                </div>
                <div className="ed-com-t1-social" style={{ marginTop: "0px" }}>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* LOGO AND MENU SECTION */}
        <div className="top-logo" data-spy="affix" data-offset-top={250}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="wed-logo">
                  <Link to="/">
                    <img
                      src="images/diet_logo.png"
                      alt="error"
                    // style={inlineLogo}
                    />
                    <strong className="logoName">
                      जिला शिक्षा एवं प्रशिक्षण <br />
                      संस्थान महासमुंद छ. ग.
                    </strong>
                  </Link>
                </div>
                <div className="main-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="about-menu">
                      <Link to="/about" className="mm-arr">
                        About us
                      </Link>
                      {/* MEGA MENU 1 */}
                      <div className="mm-pos">
                        <div className="about-mm m-menu">
                          <div className="m-menu-inn">
                            {aboutData.map((data, i) => {
                              return (
                                <div className="mm2-com mm1-com mm1-s1" key={i}>
                                  <div className="ed-course-in">
                                    <Link
                                      className="course-overlay"
                                      to={`/about/${data._id}`}
                                    >
                                      <img
                                        src={`${apiurl}/${data.image}`}
                                        alt="error"
                                      />
                                      <span>{data.heading}</span>
                                    </Link>
                                  </div>
                                  <p id={data._id}>Data Not Found</p>
                                  <Link
                                    to={`/about/${data._id}`}
                                    className="mm-r-m-btn"
                                  >
                                    Read more
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                          <div className="text-center">
                            <Link
                              className="mm-r-m-btn aboutPrePage"
                              onClick={() => setPageNumber(pageNumber - 1)}
                            >
                              Previous
                            </Link>
                            <Link
                              className="mm-r-m-btn aboutNextPage"
                              onClick={() => setPageNumber(pageNumber + 1)}
                            >
                              Next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to="/courses">All Courses</Link>
                    </li>
                    <li>
                      <Link to="/faculties">Faculties</Link>
                    </li>
                    {/* <li className="cour-menu">
                                            <a href="#!" className="mm-arr">All Pages</a>
                                            <div className="mm-pos">
                                                <div className="cour-mm m-menu">
                                                    <div className="m-menu-inn">
                                                        <div className="mm1-com mm1-cour-com mm1-s3">
                                                            <h4>Frontend pages:1</h4>
                                                            <ul>
                                                                <li><a href="index-2.html">Home</a></li>
                                                                <li><a href="index-1.html">Home - 1</a></li>
                                                                <li><a href="all-courses.html">All Courses</a></li>
                                                                <li><a href="course-details.html">Course Details</a></li>
                                                                <li><a href="about.html">About us</a></li>
                                                                <li><a href="admission.html">admission</a></li>
                                                                <li><a href="awards.html">awards</a></li>
                                                                <li><a href="blog.html">blog</a></li>
                                                                <li><a href="blog-details.html">blog details</a></li>
                                                                <li><a href="contact-us.html">contact us</a></li>
                                                                <li><a href="departments.html">Departments</a></li>
                                                                <li><a href="events.html">events</a></li>
                                                                <li><a href="event-details.html">event details</a></li>
                                                                <li><a href="event-register.html">event register</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mm1-com mm1-cour-com mm1-s3">
                                                            <h4>Frontend pages:2</h4>
                                                            <ul>
                                                                <li><a href="facilities.html">facilities</a></li>
                                                                <li><a href="facilities-detail.html">facilities detail</a></li>
                                                                <li><a href="research.html">research</a></li>
                                                                <li><a href="seminar.html">seminar</a></li>
                                                                <li><a href="gallery-photo.html">gallery photo</a></li>
                                                            </ul>
                                                            <h4 className="ed-dr-men-mar-top">User Dashboard</h4>
                                                            <ul>
                                                                <li><a href="dashboard.html">Student profile</a></li>
                                                                <li><a href="db-courses.html">Dashboard courses</a></li>
                                                                <li><a href="db-exams.html">Dashboard exams</a></li>
                                                                <li><a href="db-profile.html">Dashboard profile</a></li>
                                                                <li><a href="db-time-line.html">Dashboard timeline</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mm1-com mm1-cour-com mm1-s3">
                                                            <h4>Admin panel:1</h4>
                                                            <ul>
                                                                <li><a href="admin.html">admin</a></li>
                                                                <li><a href="admin-add-courses.html">Add new course</a></li>
                                                                <li><a href="admin-all-courses.html">All courses</a></li>
                                                                <li><a href="admin-student-details.html">Student details</a>
                                                                </li>
                                                                <li><a href="admin-user-add.html">Add new user</a></li>
                                                                <li><a href="admin-user-all.html">All users</a></li>
                                                                <li><a href="admin-panel-setting.html">Admin setting</a></li>
                                                                <li><a href="admin-event-add.html">event add</a></li>
                                                                <li><a href="admin-event-all.html">event all</a></li>
                                                                <li><a href="admin-setting.html">Admin Setting</a></li>
                                                                <li><a href="admin-slider.html">Slider setting</a></li>
                                                                <li><a href="admin-slider-edit.html">Slider edit</a></li>
                                                                <li><a href="admin-course-details.html">course details</a></li>
                                                                <li><a href="admin-login.html">admin login</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mm1-com mm1-cour-com mm1-s3">
                                                            <h4>Admin panel:2</h4>
                                                            <ul>
                                                                <li><a href="admin-event-edit.html">event edit</a></li>
                                                                <li><a href="admin-exam-add.html">exam add</a></li>
                                                                <li><a href="admin-exam-all.html">exam all</a></li>
                                                                <li><a href="admin-exam-edit.html">exam edit</a></li>
                                                                <li><a href="admin-export-data.html">export data</a></li>
                                                                <li><a href="admin-import-data.html">import data</a></li>
                                                                <li><a href="admin-job-add.html">Add new jobs</a></li>
                                                                <li><a href="admin-job-all.html">All jobs</a></li>
                                                                <li><a href="admin-job-edit.html">Edit job</a></li>
                                                                <li><a href="admin-main-menu.html">main menu</a></li>
                                                                <li><a href="admin-page-add.html">Add new page</a></li>
                                                                <li><a href="admin-page-all.html">All pages</a></li>
                                                                <li><a href="admin-page-edit.html">Edit page</a></li>
                                                                <li><a href="admin-forgot.html">forgot password</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mm1-com mm1-cour-com mm1-s4">
                                                            <h4>Admin panel:3</h4>
                                                            <ul>
                                                                <li><a href="admin-quick-link.html">quick link</a></li>
                                                                <li><a href="admin-seminar-add.html">Add new seminar</a></li>
                                                                <li><a href="admin-seminar-all.html">All seminar</a></li>
                                                                <li><a href="admin-seminar-edit.html">Edit seminar</a></li>
                                                                <li><a href="admin-seminar-enquiry.html">Semester enquiry</a>
                                                                </li>
                                                                <li><a href="admin-all-enquiry.html">All enquiry</a></li>
                                                                <li><a href="admin-view-enquiry.html">All enquiry view</a></li>
                                                                <li><a href="admin-event-enquiry.html">event enquiry</a></li>
                                                                <li><a href="admin-admission-enquiry.html">Admission enquiry</a>
                                                                </li>
                                                                <li><a href="admin-common-enquiry.html">common enquiry</a></li>
                                                                <li><a href="admin-course-enquiry.html">course enquiry</a></li>
                                                                <li><a href="admin-all-menu.html">menu all</a></li>
                                                                <li><a href="admin-about-menu.html">Menu - About</a></li>
                                                                <li><a href="admin-admission-menu.html">Menu - admission</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                    <li>
                      <Link to="/training">Training</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/notice">Notice</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="all-drop-down-menu"></div>
            </div>
          </div>
        </div>

        {/* Search Bar */}
        {/* <div className="search-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="search-form">
                                    <form>
                                        <div className="sf-type">
                                            <div className="sf-input">
                                                <input type="text" id="sf-box" placeholder="Search course and discount courses" />
                                            </div>
                                            <div className="sf-list">
                                                <ul>
                                                    <li><a href="course-details.html">Accounting/Finance</a></li>
                                                    <li><a href="course-details.html">civil engineering</a></li>
                                                    <li><a href="course-details.html">Art/Design</a></li>
                                                    <li><a href="course-details.html">Marine Engineering</a></li>
                                                    <li><a href="course-details.html">Business Management</a></li>
                                                    <li><a href="course-details.html">Journalism/Writing</a></li>
                                                    <li><a href="course-details.html">Physical Education</a></li>
                                                    <li><a href="course-details.html">Political Science</a></li>
                                                    <li><a href="course-details.html">Sciences</a></li>
                                                    <li><a href="course-details.html">Statistics</a></li>
                                                    <li><a href="course-details.html">Web Design/Development</a></li>
                                                    <li><a href="course-details.html">SEO</a></li>
                                                    <li><a href="course-details.html">Google Business</a></li>
                                                    <li><a href="course-details.html">Graphics Design</a></li>
                                                    <li><a href="course-details.html">Networking Courses</a></li>
                                                    <li><a href="course-details.html">Information technology</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="sf-submit">
                                            <input type="submit" defaultValue="Search Course" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </section>
      {/*END HEADER SECTION*/}
    </div>
  );
}

export default Header;
