import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { format, parseISO } from 'date-fns';

function Events() {

    const apiurl = process.env.REACT_APP_API_URI;
    const [eventData, setEventData] = useState([]);
    const [pageNumber, setPageNumber] = useState();
    const [pagination, setPagination] = useState([]);
    console.log(eventData);

    // It use for show number of pages
    const handelAllEventData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/event/getOne/`)
                .then((result) => {
                    let count = result.data.length / 8;
                    if (count <= 1) {
                        // Hide and show next button
                        const nextPage = document.getElementsByClassName("nextPage");
                        nextPage[0].classList.add("hidden")
                    }
                    for (let i = 0; i < count; i++) {
                        setPagination((current) => [...current, <li className="waves-effect middlePageNo" key={i} onClick={(e) => setPageNumber(i + 1)}><Link>{i + 1}</Link></li>]);
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Get the current page data from the server
    const handelEventData = async () => {
        try {
            await axios
                .get(`${apiurl}diet/event/get/${pageNumber}`)
                .then((result) => {
                    setEventData(result.data);
                    result.data.map((data) => {
                        // console.log(data.Desc);
                        onLoadContent(data.Desc, data._id);
                    });
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Convert text file to text format
    const onLoadContent = async (descText, divId) => {
        await fetch(`${apiurl}${descText}`)
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                const regex = /(<([^>]+)>)/gi;
                const newString = data.replace(regex, " ");
                const content = document.getElementById(divId);
                content.innerHTML = newString;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // when changing the page number then it call
    useEffect(() => {
        handelEventData();

        // Hide and show previous button
        const prePage = document.getElementsByClassName("prePage");
        pageNumber > 1 ? prePage[0].classList.remove("hidden") : prePage[0].classList.add("hidden");

        // Hide and show next button
        const nextPage = document.getElementsByClassName("nextPage");
        if (pagination.length) {
            pageNumber >= pagination.length ? nextPage[0].classList.add("hidden") : nextPage[0].classList.remove("hidden");
        }

    }, [pageNumber]);

    useEffect(() => {
        handelAllEventData();
        setPageNumber(1)
    }, []);

    return (
        <div>
            <section>
                <div className="head-2">
                    <div className="container">
                        <div className="head-2-inn head-2-inn-padd-top">
                            <h1>Events</h1>
                            <p>Join Us for Engaging Gatherings, Insightful Discussions, and Memorable Experiences</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container com-sp">
                    <div className="row">
                        <div className="cor about-sp">
                            <div className="ed-about-tit">
                                <div>
                                    <div className="ho-event pg-eve-main">
                                        <ul>
                                            {eventData.map((data, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="ho-ev-date pg-eve-date"><span>{format(parseISO(data.Event_date), 'dd')}</span><span>{format(parseISO(data.Event_date), 'MMM yyyy')}</span>
                                                        </div>
                                                        <div className="ho-ev-link pg-eve-desc">
                                                            <h4>{data.Event_Name}</h4>
                                                            <p id={data._id}></p>
                                                            {/* <span>Uploaded Date: {format(parseISO(data.Date), 'dd-MM-yyyy')}</span> */}
                                                        </div>
                                                        <div className="pg-eve-reg">
                                                            <Link to={`/event_details/${data._id}`} >Read more</Link>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="pg-pagina">
                                    <ul className="pagination">
                                        <li className="prePage waves-effect">
                                            <Link onClick={() => setPageNumber(pageNumber - 1)}><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
                                        </li>

                                        {pagination.map((data, index) => <>{data}</>)}

                                        <li className="nextPage waves-effect">
                                            <Link onClick={() => setPageNumber(pageNumber + 1)}><i class="fa fa-angle-right" aria-hidden="true"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Events