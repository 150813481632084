import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./css/imageSetting.css";
import { Link } from "react-router-dom";

function About() {
  const { id } = useParams();

  const apiurl = process.env.REACT_APP_API_URI;
  const [cardData, setCardData] = useState([]);
  const [pageData, setPageData] = useState({
    _id: "64e354cadd6a61d0e431beda",
    heading: "About Heading",
    image: "images/file_1692619977966-601700094.jpg",
    desc: "/content_desc/About Heading-3.txt",
    Date: "2023-08-20T09:22:38.444Z",
    __v: 0,
  });

  const handelPageData = async () => {
    if (id) {
      console.log("running handel");
      try {
        await axios
          .get(`${apiurl}diet/about/get/${id}`)
          .then((result) => {
            setPageData(result.data.data);
            onLoadContent(result.data.data.desc, result.data.data._id);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handelAboutData = async () => {
    try {
      await axios.get(`${apiurl}diet/about/get`).then((res) => {
        const data = res.data.data;
        setCardData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  // Convert text file to text format
  const onLoadContent = async (descText, divId) => {
    await fetch(`${apiurl}${descText}`)
      .then((response) => {
        return response.text();
        // console.log(response);
      })
      .then((data) => {
        console.log(data);
        // console.log(divId);
        const content = document.getElementById(`about${divId}`);
        content.innerHTML = data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handelPageData();
    handelAboutData();
  }, [id]);

  return (
    <div>
      <section>
        <div className="head-2">
          <div className="container">
            <div className="head-2-inn head-2-inn-padd-top">
              <h1>About</h1>

              {/* <div id={pageData._id}>data</div> */}
            </div>
          </div>
        </div>
      </section>
      {/*SECTION START*/}
      <section>
        <div className="container com-sp pad-bot-70">
          <div className="row">
            <div className="cor about-sp">
              <div className="ed-about-sec1">
                <div className="ed-advan">
                  <div className="row">
                    <div className="col-lg-6">
                      <img
                        src={`${apiurl}/${pageData.image}`}
                        alt="error"
                        style={{ width: "100%" }}
                      />
                      {/* <img src="https://cdn.pixabay.com/photo/2015/04/19/08/32/rose-729509_640.jpg" alt="error" style={{ width: '100%' }} /> */}
                    </div>
                    <div className="col-lg-6">
                      <h2 style={{ marginTop: "0" }}>{pageData.heading}</h2>
                      <div id={`about${pageData._id}`}>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ed-about-sec1">
                <div className="col-md-6" />
                <div className="col-md-6" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*SECTION END*/}

      <section>
        <Container>
          <Row>
            {cardData.map((data, index) => {
              return (
                <Col xs={12} md={6}>
                  <Card style={{ width: "35 rem" }}>
                    <Card.Img variant="top" src={`${apiurl}${data.image}`} />
                    <Card.Body>
                      <Card.Title>{data.heading}</Card.Title>
                      <Card.Text></Card.Text>
                      <Link to={`/about/${data._id}`}>
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            scrollToTop();
                          }}
                        >
                          Read More..
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default About;
