import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginStart, loginSuccess } from "../store/userSlice";

function Modal() {
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URI;

  const [user, setUser] = useState([]);
  const [msg, setMsg] = useState();
  const [email, setEmail] = useState("");
  const [forgetMsg, setForgetMsg] = useState([]);
  const [values, setValues] = useState({
    UserName: "",
    Password: "",
  });

  console.group(forgetMsg);

  const handleFadeIn = () => {
    const modal = document.getElementById("modal1");
    modal.classList.remove("in");
    document.body.classList.remove("modal-open");
    document.querySelector(".modal-backdrop").classList.remove("in");
    // document.body.style.paddingRight = "0px";
    document.body.style.removeProperty("padding-right");
    document.querySelector(".validate").style.borderBottom = "1px solid white";
    values.UserName = "";
    values.Password = "";
  };
  const handleChange = (e) => {
    const curent = e.target.value;
    document.querySelector("#passwordCustome").style.display = "none";
    document.querySelector("#userCustome").style.display = "none";
    setValues({
      ...values,
      [e.target.name]: curent,
    });
  };
  const handleMessage = (data) => {
    if (data === "Wrong credentials") {
      document.querySelector("#passwordCustome").style.display = "block";
    } else if (data === "User not found") {
      document.querySelector("#userCustome").style.display = "block";
    }
  };

  const handleForgate = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(`${apiUrl}diet/user/forget-password`, { email })
        .then((res) => {
          const data = res.data.message;
          setForgetMsg(data);
        });
    } catch (error) { }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      axios
        .post(`${apiUrl}diet/user/login`, {
          ...values,
        })
        .then((result) => {
          const data = result.data;
          dispatch(loginSuccess(data));
          setUser(data);
          handleFadeIn();
          window.location = `https://admin.dietmahasamund.org.in/`
        })
        .catch((err) => {
          setMsg(err.response.data.message);
          handleMessage(err.response.data.message);
        });
    } catch (error) { }
  };

  return (
    <div>
      {/*SECTION LOGIN, REGISTER AND FORGOT PASSWORD*/}
      <section>
        {/* LOGIN SECTION */}
        <div id="modal1" className="modal fade" role="dialog">
          <div className="log-in-pop">
            <div className="log-in-pop-left">
              <h1>Hello</h1>
              <p>
                Welcome, Administrator! Your expertise and authority play a
                crucial role in managing our platform effectively. Thank you for
                being part of our team.
              </p>

              {/* <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" /> Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google" /> Google+
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" /> Twitter
                  </a>
                </li>
              </ul> */}
            </div>
            <div className="log-in-pop-right">
              <a href="#" className="pop-close" data-dismiss="modal">
                <img src="images/cancel.png" alt="error" />
              </a>
              <h4>Login</h4>

              <form className="s12">
                <div>
                  <div className="input-field s12">
                    <input
                      type="text"
                      data-ng-model="name"
                      className="validate"
                      name="UserName"
                      value={values.UserName}
                      onChange={handleChange}
                    />
                    <span
                      id="userCustome"
                      style={{ color: "red", display: "none" }}
                    >
                      User not Found
                    </span>
                    <label>User name</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    <input
                      type="password"
                      className="validate"
                      name="Password"
                      value={values.Password}
                      onChange={handleChange}
                    />
                    <span
                      id="passwordCustome"
                      style={{ color: "red", display: "none" }}
                    >
                      Wrong Password
                    </span>
                    <label>Password</label>
                  </div>
                </div>
                <div></div>
                <div>
                  <div
                    className="input-field s4"
                    onClick={(e) => {
                      handleLogin(e);
                    }}
                  >
                    <input
                      type="submit"
                      defaultValue="Login"
                      className="waves-effect waves-light log-in-btn"
                    />
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    {" "}
                    <a
                      href="#"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#modal3"
                    >
                      Forgot password
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* REGISTER SECTION */}
        {/* <div id="modal2" className="modal fade" role="dialog">
          <div className="log-in-pop">
            <div className="log-in-pop-left">
              <h1>Hello...</h1>
              <p>
                Don't have an account? Create your account. It's take less then
                a minutes
              </p>
              <h4>Login with social media</h4>
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" /> Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google" /> Google+
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" /> Twitter
                  </a>
                </li>
              </ul>
            </div>
            <div className="log-in-pop-right">
              <a href="#" className="pop-close" data-dismiss="modal">
                <img src="images/cancel.png" alt="error" />
              </a>
              <h4>Create an Account</h4>
              <p>
                Don't have an account? Create your account. It's take less then
                a minutes
              </p>
              <form className="s12">
                <div>
                  <div className="input-field s12">
                    <input
                      type="text"
                      data-ng-model="name1"
                      className="validate"
                    />
                    <label>User name</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    <input type="email" className="validate" />
                    <label>Email id</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    <input type="password" className="validate" />
                    <label>Password</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    <input type="password" className="validate" />
                    <label>Confirm password</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s4">
                    <input
                      type="submit"
                      defaultValue="Register"
                      className="waves-effect waves-light log-in-btn"
                    />
                  </div>
                </div>
                <div>
                  <div className="input-field s12">
                    {" "}
                    <a
                      href="#"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#modal1"
                    >
                      Are you a already member ? Login
                    </a>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        {/* FORGOT SECTION */}
        <div id="modal3" className="modal fade" role="dialog">
          <div className="log-in-pop">
            <div className="log-in-pop-left">
              <h1>Hello... </h1>
              <p></p>

              {/* <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" /> Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google" /> Google+
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" /> Twitter
                  </a>
                </li>
              </ul> */}
            </div>
            <div className="log-in-pop-right">
              <a href="#" className="pop-close" data-dismiss="modal">
                <img src="images/cancel.png" alt="error" />
              </a>
              <h4>Forgot password</h4>

              <form className="s12">
                <div>
                  <div className="input-field s12">
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="text"
                      data-ng-model="name3"
                      className="validate"
                      value={email}
                    />
                    <span>{forgetMsg}</span>
                    <label> email id</label>
                  </div>
                </div>
                <div>
                  <div className="input-field s4">
                    <input
                      onClick={(e) => {
                        handleForgate(e);
                      }}
                      type="submit"
                      defaultValue="Submit"
                      className="waves-effect waves-light log-in-btn"
                    />
                  </div>
                </div>
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Modal;
