import React from "react";
import AboutForm from "./AboutForm";
import "./admin_css/dashboard.css";

function Dashboard() {
  return (
    <div className="rightPage py-5">
      <AboutForm />
    </div>
  );
}

export default Dashboard;
