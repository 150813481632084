import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function Course_Details() {
  const { id } = useParams();
  const apiurl = process.env.REACT_APP_API_URI;
  const [courseData, setCourseData] = useState([]);
  // console.log(courseData);

  // Get the current page data from the server
  const handelCourseData = async () => {
    try {
      await axios
        .get(`${apiurl}diet/course/getOne/${id}`)
        .then((result) => {
          setCourseData(result.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handelCourseData();
  }, []);

  return (
    <div>
      <section>
        <div className="head-2">
          <div className="container">
            <div className="head-2-inn head-2-inn-padd-top">
              <h1>Course Detail</h1>
              <p>
                Fusce id sem at ligula laoreet hendrerit venenatis sed purus. Ut
                pellentesque maximus lacus, nec pharetra augue.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container com-sp pad-bot-70 pg-inn">
          <div className="row">
            <div className="cor">
              <div className="col-md-12">
                <div className="cor-p1 mt-0 pb-0">
                  <h2>{courseData.Heading}</h2>
                  {/* <span>Category: Software Testing</span> */}
                </div>
                <div className="cor-mid-img">
                  <img src="images/course.jpg" alt="error" />
                </div>
                <div className="cor-con-mid">
                  <div className="cor-p4">
                    <h3>Course Details:</h3>
                    <p id={courseData._id}>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias, aut adipisci? Fugit temporibus tempora amet
                      eligendi, mollitia itaque libero inventore quod labore
                      incidunt, officia saepe tempore alias quia suscipit
                      veritatis?
                    </p>
                  </div>
                  {/* <div className="cor-p4">
                                        <h3>Course Syllabus</h3>
                                        <p> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div> */}
                  <div className="cor-p5">
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a data-toggle="tab" href="#home">
                          <img src="images/icon/cor4.png" alt="error" />{" "}
                          <span>Requirements</span>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#menu1">
                          <img src="images/icon/cor3.png" alt="error" />
                          <span>Fees</span>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          <img src="images/icon/cor5.png" alt="error" />
                          <span>How to Apply</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="home" className="tab-pane fade in active mt-5">
                        {/* <h4>Requirments:</h4> */}
                        <p>{courseData.Requirments}</p>
                      </div>
                      <div id="menu1" className="tab-pane fade mt-5">
                        {/* <h4>Menu 1</h4> */}
                        <p>{courseData.Fees}</p>
                      </div>
                      <div id="menu2" className="tab-pane fade mt-5">
                        {/* <h4>Menu 2</h4> */}
                        <p>{courseData.How_To_Apply}</p>
                      </div>
                    </div>
                  </div>
                  <div className="cor-p4">
                    <h3>Subjects And Sylabus:</h3>
                    <p>
                      In this see our subjects details and download pdf for more
                      details
                    </p>
                    <ul className="collapsible" data-collapsible="accordion">
                      <li>
                        <div className="collapsible-header active">
                          {console.log(courseData.Subjects)}
                          {courseData.Subjects !== undefined ? (
                            courseData.Subjects.split(",").map((sname, i) => {
                              return courseData.Subjects.split(",").length ==
                                i + 1
                                ? sname
                                : sname + ", ";
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          className="collapsible-body cor-tim-tab"
                          style={{ borderBottom: "1px solid #ddd" }}
                        >
                          {/* <h4>First Year Courses</h4> */}
                          <ul>
                            <li>
                              <a href="#">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form, by injected humour
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form, by injected humour
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form, by injected humour
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3">
                            <div className="de-left-tit" style={{ marginBottom: '30px' }}>
                                <h4>More Courses</h4>
                            </div>
                            <div className="cor-side-com">
                                <div className="ho-ev-latest ho-ev-latest-bg-2">
                                    <div className="ho-lat-ev">
                                        <a href="#">
                                            <h4>Job Vacants</h4>
                                            <p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="ho-ev-latest in-ev-latest-bg-1">
                                    <div className="ho-lat-ev">
                                        <a href="#">
                                            <h4>Job Vacants</h4>
                                            <p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="ho-ev-latest in-ev-latest-bg-1">
                                    <div className="ho-lat-ev">
                                        <a href="#">
                                            <h4>Job Vacants</h4>
                                            <p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Course_Details;
