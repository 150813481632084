import React from 'react'

function PageNotFound() {
    return (
        <div className="container my-5">
            <img src="images/PageNotFound.jpeg" alt="error" style={{ width: "100%" }} />
        </div>
    )
}

export default PageNotFound