import styled from "styled-components";

export const Styles = styled.div`
.copyright-area {
  padding: 14px 0;
  background: #021c3a;


  .copy-text {
    p {
      color: #969696;
      padding-top: 3px;
      margin: 0 !important;
      font-size: 12px;

      i {
        color: #11B67A;
        margin: 0 2px;
      }

      a {
        color: #11B67A;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  ul.social {
    li {
      a {
        text-align: center;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          border-width: 8px 14px;
          border-style: solid;
          border-top-color: transparent;
          border-right-color: transparent;
          border-left-color: transparent;
          top: -16px;
          left: 0;
          z-index: 1;
          transition: all 0.2s ease;
        }

        &:after {
          content: "";
          position: absolute;
          border-width: 8px 14px;
          border-style: solid;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          bottom: -16px;
          left: 0;
          z-index: 1;
          transition: all 0.2s ease;
        }

        i {
          font-size: 14px;
          color: #ffffff;
          width: 28px;
        }

        &:hover {
          background-color: #11B67A !important;

          &:before {
            border-bottom-color: #11B67A !important;
          }

          &:after {
            border-top-color: #11B67A !important;
          }
        }
      }

      &:nth-child(1) {
        a {
          background-color: #4267b2;

          &:before {
            border-bottom-color: #4267b2;
          }

          &:after {
            border-top-color: #4267b2;
          }
        }
      }

      &:nth-child(2) {
        a {
          background-color: #1da1f2;

          &:before {
            border-bottom-color: #1da1f2;
          }

          &:after {
            border-top-color: #1da1f2;
          }
        }
      }

      &:nth-child(3) {
        a {
          background-color: #2867b2;

          &:before {
            border-bottom-color: #2867b2;
          }

          &:after {
            border-top-color: #2867b2;
          }
        }
      }

      &:nth-child(4) {
        a {
          background-color: #dd1343;

          &:before {
            border-bottom-color: #dd1343;
          }

          &:after {
            border-top-color: #dd1343;
          }
        }
      }

      &:nth-child(5) {
        a {
          background-color: #ea4c89;

          &:before {
            border-bottom-color: #ea4c89;
          }

          &:after {
            border-top-color: #ea4c89;
          }
        }
      }
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}`;